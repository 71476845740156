import { Controller } from "@hotwired/stimulus";
import { track } from "../../helpers/app";
import { addOptionsToSelect } from "../../helpers/form";

// Used on the documents and resources pages to download
// certification letters given a selected state and module type
export default class extends Controller {
  static targets = [
    "asceSelect",
    "railPlatformSelect",
    "stateSelect",
    "railTypeSelect",
    "product",
    "foundationTypeSelect",
  ];
  static values = { baseUrl: String };

  productTrackingCategories = {
    "pitched-roof": "Pitched Roof Mount",
    "flat-roof": "Flat Roof Mount",
    "ground-mount": "Ground Mount",
    bx: "BX",
  };

  connect() {
    this.product = this.productTarget.value;

    this.#manageRailPlatformSelect();
    this.#manageStateSelect();
    this.#disableRailTypeSelect(true);
    this.#disableFoundationTypeSelect(true);
  }

  asceChange() {
    this.#manageRailPlatformSelect();
    this.#manageStateSelect();
    this.#manageRailTypeSelect();
    this.#manageFoundationTypeSelect();
  }

  railPlatformChange() {
    this.#manageStateSelect();
    this.#manageRailTypeSelect();
    this.#manageFoundationTypeSelect();
  }

  stateChange() {
    this.#manageRailTypeSelect();
    this.#manageFoundationTypeSelect();
  }

  railTypeChange() {
    this.#manageFoundationTypeSelect();
  }

  download(_) {
    const state = this.stateSelectTarget.value;
    const railType = this.railTypeSelectTarget.value;
    const foundationType = this.hasFoundationTypeSelectTarget && this.foundationTypeSelectTarget.value;

    if (this.hasAsceSelectTarget && this.asceSelectTarget.value === "") {
      alert("Please select an ASCE");
      return;
    }

    if (this.hasRailPlatformSelectTarget && this.railPlatformSelectTarget.value === "") {
      alert("Please select a rail platform");
      return;
    }

    if (state === "") {
      alert("Please select a state.");
      return;
    }

    if (railType === "") {
      alert("Please select a rail type.");
      return;
    }

    if (this.hasFoundationTypeSelectTarget && this.foundationTypeSelectTarget.value === "") {
      alert("Please select a foundation type");
      return;
    }

    const fileUrl = this.#fileUrl(state, railType, foundationType);
    track({ action: "Document Download", category: this.productTrackingCategories[this.product], label: fileUrl });
    setTimeout(() => {
      window.open(fileUrl, "_blank");
    }, 250);
  }

  // Private methods for managing cascading selects

  get #asce() {
    if (this.hasAsceSelectTarget) {
      return this.asceSelectTarget.value;
    } else {
      // Use this as a default value for D&R pages that don't have an ASCE selector (GB)
      return "7.10";
    }
  }

  get #railPlatform() {
    if (this.hasRailPlatformSelectTarget) {
      return this.railPlatformSelectTarget.value;
    } else if (this.product === "bx") {
      return "bx";
    } else {
      // Use this as a default value for D&R pages that don't have a rail platform value
      return "xr";
    }
  }

  #manageRailPlatformSelect() {
    const asce = this.#asce;

    this.#disableRailPlatformSelect(asce === "");
    this.#populateRailPlatformSelect(asce);
  }

  #manageStateSelect() {
    const asce = this.#asce;
    const railPlatform = this.#railPlatform;

    this.#disableStateSelect(asce === "" || railPlatform === "");
    this.#populateStateSelect(asce, railPlatform);
  }

  #manageRailTypeSelect() {
    const railPlatform = this.#railPlatform;
    const state = this.stateSelectTarget.value;

    this.#disableRailTypeSelect(railPlatform === "" || state === "");
    this.#populateRailTypeSelect(railPlatform, state);
  }

  #manageFoundationTypeSelect() {
    if (!this.hasFoundationTypeSelectTarget) return;

    const railType = this.railTypeSelectTarget.value;
    const noRailTypeIsSelected = this.railTypeSelectTarget.value == "";

    this.#disableFoundationTypeSelect(noRailTypeIsSelected);
    if (noRailTypeIsSelected) this.foundationTypeSelectTarget.value = "";
  }

  #populateRailPlatformSelect(asce) {
    if (!this.hasRailPlatformSelectTarget) return;
    if (asce === "") return;

    const railPlatformValue = this.railPlatformSelectTarget.value;
    const railPlatformsForAsce = JSON.parse(this.stateSelectTarget.dataset.asceStates)[asce];
    const statesForAire = railPlatformsForAsce["aire"];
    const disableAire = statesForAire.length === 0;
    const statesForXr = railPlatformsForAsce["xr"];
    const disableXR = statesForXr.length === 0;

    const railPlatformOptions = [
      ["Aire", "aire", disableAire, !disableAire && railPlatformValue === "aire"],
      ["XR", "xr", disableXR, !disableXR && railPlatformValue === "xr"],
    ];
    addOptionsToSelect(railPlatformOptions, this.railPlatformSelectTarget, true, "Rail platform");
  }

  #populateStateSelect(asce, railPlatform) {
    if (!this.hasStateSelectTarget) return;

    if (asce === "" || railPlatform === "") return;

    const stateValue = this.stateSelectTarget.value;
    const statesForAsce = JSON.parse(this.stateSelectTarget.dataset.asceStates)[asce][railPlatform];
    const statesOptions = statesForAsce.map((state) => {
      // value, text, disabled, selected
      return [state[1], state[0], false, state[0] === stateValue];
    });
    addOptionsToSelect(statesOptions, this.stateSelectTarget, true, "State");
  }

  #populateRailTypeSelect(railPlatform, state) {
    if (!this.hasRailTypeSelectTarget) return;

    let railTypesOptions = [];
    if (railPlatform !== "" && state !== "") {
      const railTypeValue = this.railTypeSelectTarget.value;
      railTypesOptions = this.#railTypesForProduct(railPlatform).map((railType) => {
        // value, text, disabled, selected
        return [railType[1], railType[0], false, railType[0] === railTypeValue];
      });
    }

    addOptionsToSelect(railTypesOptions, this.railTypeSelectTarget, true, "Rail type");
  }

  #railTypesForProduct(railPlatform) {
    if (this.product === "bx") return [];
    if (this.product === "ground-mount")
      return [
        ["XR100SGA4", "4-up XR100"],
        ["XR1000SGA4", "4-up XR1000"],
        ["XR1000SGA5", "5-up XR1000"],
      ];
    if (railPlatform === "aire") {
      return [
        ["A1", "A1"],
        ["A2", "A2"],
      ];
    } else {
      return [
        ["XR10", "XR10"],
        ["XR100", "XR100"],
        ["XR1000", "XR1000"],
      ];
    }
  }

  #disableRailPlatformSelect(bool) {
    if (!this.hasRailPlatformSelectTarget) return;

    this.railPlatformSelectTarget.disabled = bool;
  }

  #disableStateSelect(bool) {
    if (!this.hasStateSelectTarget) return;

    this.stateSelectTarget.disabled = bool;
  }

  #disableRailTypeSelect(bool) {
    if (!this.hasRailTypeSelectTarget) return;

    this.railTypeSelectTarget.disabled = bool;
  }

  #disableFoundationTypeSelect(bool) {
    if (!this.hasFoundationTypeSelectTarget) return;

    this.foundationTypeSelectTarget.disabled = bool;
  }

  // Private methods for building download URLs

  #fileUrl(state, railType, foundationType) {
    switch (this.product) {
      case "pitched-roof":
        return this.#pitchedRoofFileUrl(state, railType);
      case "flat-roof":
        return this.#flatRoofFileUrl(state, railType);
      case "ground-mount":
        return this.#groundMountFileUrl(state, railType, foundationType);
      case "bx":
        return this.#bxFileUrl(state, railType);
      default:
        throw `unrecognized product: ${product}`;
    }
  }

  #pitchedRoofFileUrl(state, railType) {
    // <S3_BUCKET_URL>/pitched-roof-mounting/resources/certification/XR1000/IronRidge_XR1000Flush_Certification_UFO_CO.pdf
    const xrPlusSuffix = this.#xrPlusSuffix(state, railType);

    return [
      this.baseUrlValue,
      "pitched-roof-mounting",
      "resources",
      "certification",
      railType,
      `IronRidge_${railType}Flush_Certification_UFO_${state}${this.#asceFilenamePiece}${xrPlusSuffix}.pdf`,
    ].join("/");
  }

  #xrPlusSuffix(state, railType) {
    // only applies to PR which always has an ASCE selector
    const asceValue = this.asceSelectTarget.value;

    if (asceValue !== "7.10") return "";
    if (railType === "A1" || railType === "A2") return "";
    return "-Plus";
  }

  #flatRoofFileUrl(state, railType) {
    // <S3_BUCKET_URL>/flat-roof-mounting/resources/certification/XR1000/IronRidge_XR1000Tilt_FL_UFO.pdf
    return [
      this.baseUrlValue,
      "flat-roof-mounting",
      "resources",
      "certification",
      railType,
      `IronRidge_${railType}Tilt_Certification_${state}_UFO${this.#asceFilenamePiece}.pdf`,
    ].join("/");
  }

  #groundMountFileUrl(state, railType, foundationType) {
    // <S3_BUCKET_URL>/groundmounting/certification/XR1000SGA5/IronRidge_XR1000SGA5_Certification_UFO_CA.pdf
    // <S3_BUCKET_URL>/groundmounting/certification/XR100SGA4/IronRidge_XR100SGA4_Certification_UFO_GS_VA-7-22.pdf
    const foundationCode = foundationType == "ground_screws" ? "_GS" : "";
    const ascePiece = this.#asce === "7.22" ? "-7-22" : "";
    const fileName = `IronRidge_${railType}_Certification_UFO${foundationCode}_${state}${ascePiece}.pdf`;

    return [this.baseUrlValue, "groundmounting", "certification", railType, fileName].join("/");
  }

  #bxFileUrl(state, _railType) {
    // <S3_BUCKET_URL>/flat-roof-mounting/resources/certification/BX/IronRidge_BX_Certification_DE.pdf
    return [
      this.baseUrlValue,
      "flat-roof-mounting",
      "resources",
      "certification",
      "BX",
      `IronRidge_BX_Certification_${state}${this.#asceFilenamePiece}.pdf`,
    ].join("/");
  }

  get #asceFilenamePiece() {
    if (!this.hasAsceSelectTarget) return "";

    const asceValue = this.asceSelectTarget.value;
    return asceValue === "7.10" ? "" : `-${asceValue.replace(".", "")}`;
  }
}
